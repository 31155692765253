.expertLogin {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;

  .loginLog {
    position: relative;
    width: 100%;
    height: 90px;

    // background-color: blue;
    .logoImage {
      position: absolute;
      width: 320px;
      height: 60px;
      left: 15px;
      top: 20px;
    }
  }

  .loginCenter {
    // position: absolute;
    position: relative;
    width: 100%;
    height: calc(100% - 170px);
    background: url('../../assets/images/bg2.jpg') no-repeat center;

    .loginBox {
      @media screen and (max-width: 430px) {
        right: 15px;
      }

      @media screen and (min-width: 800px) {
        right: 130px;
      }

      position: absolute;
      padding: 60px 50px 0;
      width: 480px;
      height: 620px;
      top: 50%;
      transform: translateY(-50%);
      background-color: #fff;
      border-radius: 5px;

      .loginHeader {
        margin-bottom: 36px;
        text-align: center;

        .title {
          font-size: 36px;
          font-weight: 600;
          color: #204A6F;
        }

        .subTitle {
          padding: 12px 0 0 0;
          font-size: 14px;
          font-weight: 400;
          color: #999999;
        }
      }

      .loginContent {
        width: 100%;
        margin-bottom: 36px;

        .ant-select-selector {
          color: #204A6F;
          border-radius: 4px;
          border-color: #BBE3F4;
          height: 40px;
          font-size: 14px;
          font-weight: 400;

          .ant-select-selection-search-input {
            height: 40px;
            font-size: 14px;
            font-weight: 400;
          }

          .ant-select-selection-item {
            line-height: 40px;
            font-size: 14px;
            font-weight: 400;
          }

          .ant-select-selection-placeholder {
            height: 40px;
            line-height: 40px;
            font-size: 14px;
            font-weight: 400;
          }
        }

        .ant-input-affix-wrapper {
          color: #204A6F !important;
          border-radius: 4px;
          border-color: #BBE3F4;
          height: 40px;
          font-size: 14px;
          font-weight: 400;

          .ant-input {
            color: #204A6F !important;
            font-size: 14px;
            font-weight: 400;
          }
        }

        .ant-input-prefix {
          font-size: 20px;
          margin-right: 12px;
        }

        .captchaBox {
          display: flex;
          justify-content: space-between;

          .captchaInput {
            width: calc(100% - 100px - 12px);
          }

          .captchaButton {
            width: 100px;
            height: 40px;
            font-size: 14px;
            color: #3F80FF;
            font-weight: 400;
            border: 1px solid #3F80FF;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .login-form-button {
          width: 100%;
          height: 54px;
          font-size: 24px;
          color: #fff;
          font-weight: 400;
          background: linear-gradient(91.43deg, #0194FF 1.86%, #2956F5 100%);
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          letter-spacing: -2px;
          margin-top: 24px;
        }
      }

      .loginOther {
        display: flex;
        margin-bottom: 5px;
        justify-content: space-between;

        .registerBtn {
          cursor: pointer;
          font-size: 18px;
          font-weight: 400;
          color: #3F80FF;
        }
      }

      // .loginExpert {
      //   cursor: pointer;
      //   font-size: 15px;
      //   color: #3B78DD;
      // }
      // .loginImage {
      //   position: absolute;
      //   left: 0;
      //   bottom: 0;
      //   width: 100%;
      //   height: 60px;
      //   background-color: #3B78DD;
      // }
    }
  }

  .loginFooter {
    // position: absolute;
    padding: 10px 0;
    width: 100%;
    // height: 80px;
    left: 0;
    bottom: 0;
    text-align: center;
    background-color: #eee;

    .footerSign {
      color: #aaa;

      a {
        color: #aaa;
      }

      a:hover {
        color: #3b78dd;
        text-decoration: underline;
      }

      ul,
      li {
        list-style: none;
      }

      span {
        margin-right: 14px;
      }
    }
  }

  .modalContent {
    line-height: 1.7;
  }
}