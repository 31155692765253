.registerProject {
  width: 100%;
  height: 100%;
  // background-color: #eee;
  background: url(../../assets/images/bg3.png) no-repeat center;
  background-size: 100% 100%;

  .loginLog {
    position: relative;
    width: 100%;
    height: 106px;

    // background-color: blue;
    .logoImage {
      position: absolute;
      width: 420px;
      height: 80px;
      left: 30px;
      top: 26px;
    }
  }

  .projectBox {
    width: 33%;
    //height: calc(100% - 106px - 100px - 10%);
    margin-left: 33%;
    //margin-top: 5%;
    position: absolute;

    .projectBoxTitle {
      height: 64px;
      width: 100%;
      border-radius: 12px 12px 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 24px;
      line-height: 33.6px;
      background-color: #3F80FF;
      color: #FFF;
    }

    .projectContent {
      width: 100%;
      height: 92%;
      background-color: #FFF;
      border-radius: 0 0 12px 12px;
      overflow-y: auto;

      .contactInfo {
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #204A6F;
        font-weight: 600;
        font-size: 16px;
        line-height: 22.4px;
      }

      .constitutionConfirm {
        margin-top: 10px;
        width: 100%;
        text-align: center;
        border-top: 1px solid #1890ff;

        .checkBox {
          margin: 10px 0;
          width: 100%;
        }

        .registerBtn {
          width: 150px;
        }
      }

      .tableConfirm {
        width: 100%;
        margin: 10px auto;

        .baseTitle {
          // padding: 0 40px;
          margin-bottom: 15px;
          width: 100%;
          height: 30px;
          text-align: center;
          line-height: 30px;
          color: #fff;
          background-color: #1890ff;
          // border-bottom: 1px solid ;
        }

        .ant-form {
          .ant-form-item {
            margin-left: 15%;
            margin-bottom: 10px;

            .ant-form-item-label>label {
              margin-right: 10px;
              color: #204A6F;
            }
          }
        }

        .ant-input-affix-wrapper {
          height: 32px;
          border: solid 1px #BBE3F4;
          border-radius: 4px;
        }

        .ant-select-selector {
          height: 32px;
          border: solid 1px #BBE3F4;
          border-radius: 4px;

        }

        .otherInput {
          height: 32px;
          border: solid 1px #BBE3F4;
          border-radius: 4px;

        }
      }

      .contentThreeButton {
        margin-top: 40px;
        width: 100%;
        text-align: center;
      }
    }

    .buttonItem {
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: space-around;
      margin: 5% 0;
      padding: 0 25%;

      .ant-btn {
        height: 36px;
        width: 118px;
        border-radius: 8px;
        font-weight: 600;
        font-size: 16px;
      }
      .projectRegisterSubmitBtn {
        color: #FFF;
        background: linear-gradient(to right, #0194FF, #2956F5);
      }
      .projectRegisterBackBtn {
        border: solid 1px #3F80FF;
        color: #3F80FF;
      }
    }

    .projectRegisterSuccessContent {
      width: 100%;
      background-color: #FFF;
      border-radius: 0 0 12px 12px;
      padding: 2vh 25% 3vh;

      .pRSCElementGroup {
        background-color: #FFF;

        .checkImg {
          width: 100%;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .successInfoText {
          width: 100%;
          margin-top: 1%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #204A6F;
          font-weight: 600;
          font-size: 18px;
          line-height: 25.2px;
        }

        .remindInfoText {
          width: 100%;
          margin-top: 1%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #999999;
          font-weight: 400;
          font-size: 14px;
          line-height: 19.6px;
        }

        .returnButtonRow {
          width: 100%;
          margin-top: 8%;
          display: flex;
          justify-content: center;
          align-items: center;

          .ant-btn {
            height: 37px;
            width: 112px;
            border-radius: 8px;
            font-weight: 600;
            font-size: 16px;
          }
          .projectRegisterOkBtn {
            color: #FFF;
            background: linear-gradient(to right, #0194FF, #2956F5);
          }
        }

      }
    }
  }

  .loginFooter {
    position: absolute;
    padding: 10px 0;
    width: 50%;
    height: 80px;
    left: 25%;
    bottom: 0;
    text-align: center;
    background-color: transparent;

    .footerSign {
      color: #fff;

      a {
        color: #fff;
      }

      a:hover {
        color: #3b78dd;
        text-decoration: underline;
      }

      ul,
      li {
        list-style: none;
      }

      span {
        margin-right: 14px;
      }
    }
  }

  .hideContent {
    display: none;
  }

  .checked {
    background-color: #1890ff;
    color: #fff;
  }

  .noChecked {
    background-color: #ddd;
    color: #fff;
  }
}

//移动端样式
.registerProject.mobile {
  .projectBox {
    height: calc(100% - 90px);

    .projectContent {
      height: 100%;
    }
  }

  .loginFooter {
    //display: none;
  }
}

.unitName {
  width: 100%;
  display: block;

  .ant-col-xs-12 {
    max-width: 100%;
  }
}

// 超小设备 手机 768px以下
// @media screen and (max-width: 768px) {
//   html,
//   body {
//     // font-size: calc(100vw / 18.75);
//   }

//   .registerProject {
//     background: url(../../assets/images/bg2.jpg) top center/cover no-repeat;
//     .loginLog {
//       height: 0.9rem;

//       .logoImage {
//         width: 3.2rem;
//         height: 0.6rem;
//         left: 0.15rem;
//         top: 0.2rem;
//       }
//     }
//     .projectBox {
//       padding: 0.1rem 0.4rem;
//       height: calc(100% - 1.7rem);
//       .projectStep {
//         padding: 0;
//         height: 0.75rem;
//         .ant-steps-vertical {
//           flex-direction: row;
//           zoom: 0.65;
//         }
//         .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
//           max-width: 2rem;
//         }
//       }
//       .projectContent {
//         padding: 0.1rem 0.18rem 0.2rem;
//         height: calc(100% - 0.75rem);
//         border-radius: 0.05rem;
//         .constitutionConfirm {
//           margin-top: 0.1rem;

//           .checkBox {
//             margin: 0.1rem 0;
//             .ant-checkbox-wrapper {
//               font-size: 0.26rem;
//             }
//           }
//           .registerBtn {
//             width: 1.5rem;
//             font-size: 0.26rem;
//           }
//         }
//         .tableConfirm {
//           width: 100%;

//           .baseTitle {
//             margin-bottom: 0.3rem;
//             height: 0.6rem;
//             line-height: 0.6rem;
//           }
//         }
//         .contentThreeButton {
//           margin-top: 0.4rem;
//         }
//       }
//     }
//     .loginFooter {
//       padding-top: 0.2rem;
//       height: 0.8rem;

//       .footerSign {
//         font-size: 0.14rem;
//         position: absolute;
//         left: 50%;
//         transform: translateX(-50%);
//       }
//     }
//   }

//   .contentThree {
//     padding: 0.2rem 1rem 0;
//     .contentThreeOne {
//       font-size: 0.2rem;
//     }
//     .contentThreeTwo {
//       font-size: 0.18rem;
//     }
//   }

//   .backBtn {
//     display: none;
//   }
// }