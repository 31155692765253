.forgotProject {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;

  .loginCenter {
    // position: absolute;
    position: relative;
    width: 100%;
    height: 100%;
    background: url('../../assets/images/bg3.png') no-repeat center;
    background-size: 100% 100%;

    .loginLog {
      position: relative;
      width: 100%;
      height: 106px;

      // background-color: blue;
      .logoImage {
        position: absolute;
        width: 420px;
        height: 80px;
        left: 30px;
        top: 26px;
      }
    }

    .loginBox {
      position: absolute;
      padding: 0;
      width: 32%;
      height: 100%;
      top: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      //transform: translateY(-50%);
      background-color: #fff;
      border-radius: 0;

      .loginElementGroup {
        width: 100%;
        //height: 100%;

        .loginHeader {
          //margin-top: 25%;
          margin-bottom: 50px;
          text-align: center;

          .title {
            font-size: 36px;
            font-weight: 600;
            line-height: 50.4px;
            color: #204A6F;
          }

          .subTitle {
            padding: 12px 0 0 0;
            font-size: 14px;
            font-weight: 400;
            line-height: 19.6px;
            color: #999999;
          }
        }

        .loginContent {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 10px;

          .ant-select-selector {
            color: #204A6F;
            border-radius: 4px;
            border-color: #BBE3F4;
            height: 40px;
            width: 380px;
            font-size: 14px;
            font-weight: 400;

            .ant-select-selection-search-input {
              height: 40px;
              font-size: 14px;
              font-weight: 400;
            }

            .ant-select-selection-item {
              line-height: 40px;
              font-size: 14px;
              font-weight: 400;
            }

            .ant-select-selection-placeholder {
              height: 40px;
              line-height: 40px;
              font-size: 14px;
              font-weight: 400;
            }
          }

          .ant-input {
            color: #204A6F !important;
            border-radius: 4px;
            border-color: #BBE3F4;
            height: 40px;
            font-size: 14px;
            font-weight: 400;
          }

          .ant-input-affix-wrapper {
            color: #204A6F;
            border-radius: 4px;
            border-color: #BBE3F4;
            //height: 40px;
            font-size: 14px;
            font-weight: 400;
          }

          .ant-input-prefix {
            font-size: 20px;
            margin-right: 12px;
          }

          .login-form {
            width: 65%;


            .contentTitle {
              display: flex;
              align-items: center;
              height: 20px;
              margin-bottom: 20px;

              .text {
                margin-left: 5px;
                color: #204A6F
              }
            }

            .captchaBox {
              display: flex;
              justify-content: space-between;

              .captchaInput {
                width: calc(100% - 100px - 12px);
              }

              .captchaButton {
                width: 100px;
                height: 40px;
                font-size: 14px;
                color: #fff;
                background-color: #3F80FF;
                font-weight: 400;
                border: 1px solid #3F80FF;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }

            .login-form-button {
              width: 100%;
              height: 54px;
              font-size: 24px;
              color: #fff;
              font-weight: 400;
              background: linear-gradient(91.43deg, #0194FF 1.86%, #2956F5 100%);
              border-radius: 8px;
              display: flex;
              justify-content: center;
              align-items: center;
              letter-spacing: -2px;
              margin-top: 24px;
            }
          }

        }

        .loginOther {
          display: flex;
          justify-content: space-between;
          margin: 0 16%;

          .registerBtn {
            cursor: pointer;
            font-size: 18px;
            font-weight: 400;
            color: #3F80FF;
          }
        }
      }
    }
  }

  .loginFooter {
    position: absolute;
    padding: 10px 0;
    width: 50%;
    height: 80px;
    left: 25%;
    bottom: 0;
    text-align: center;
    background-color: transparent;

    .footerSign {
      color: #fff;

      a {
        color: #fff;
      }

      a:hover {
        color: #3b78dd;
        text-decoration: underline;
      }

      ul,
      li {
        list-style: none;
      }

      span {
        margin-right: 14px;
      }
    }
  }

  .modalContent {
    line-height: 1.7;
  }
}