html,
body,
#root,
.projectLayout {
  width: 100%;
  height: 100%;
  background-color: #f7f8fc;
}

.project-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  // background-color: #3580BC;
  .project-right-box {
    display: flex;

    .project-right-one {
      margin-right: 28px;
      color: #fff;
      font-size: 14px;
      cursor: pointer;
    }

    .project-logOut {
      margin-right: 28px;
      font-size: 16px;
      color: red;
      cursor: pointer;
    }
  }
}

.project-memner-header {
  background-color: #3F80FF;
  height: 60px;
}

.ant-layout-header {
  padding: 0 15px;
}

.project-sider-layout-background {
  // margin-bottom: 30px;
  width: 100%;
  background: #FFF;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  box-shadow: 8px 0 12px 0 #AED8FF40;

  // overflow-y: scroll;
  .project-site-image {
    padding: 30px 50px;
    width: 100%;

    .project-site-avatar {
      width: 100%;
      height: 80px;
      text-align: center;
      margin-bottom: 10px;

      img {
        width: 80px;
        height: 80px;
        border-radius: 80px;
      }
    }

    .project-avatar-info-name {
      margin-bottom: 10px;
      width: 100%;
      text-align: center;
      color: #204A6F;
      font-weight: 600;
      font-size: 18px;
      line-height: 25.2px;
    }

    .project-avatar-info-tel {
      margin-bottom: 10px;
      width: 100%;
      text-align: center;
      color: #999999;
      font-weight: 400;
      font-size: 14px;
      line-height: 19.6px;
    }

    .project-site-title {
      display: flex;
      padding: 0 30px;
      width: 100%;
      color: #333;
      font-size: 14px;
      justify-content: space-around;

      span {
        cursor: pointer;
      }

      a {
        color: #3F80FF;
        transition: all 0.3s;
      }

      a:hover {
        color: #3f4fff;
        text-decoration: underline;
      }
    }
  }

  .projectDevideLine {
    width: 90%;
    height: 1px;
    margin-left: 5%;
    background-color: #EEEFF0;
  }

  .project-sider-menu {
    margin-top: 20px;
  }
}

.project-site-layout-background {
  // margin-bottom: 30px;
  width: 100%;
  background: transparent;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  // overflow-y: scroll;
  .project-site-image {
    padding: 50px 50px 30px;
    width: 100%;

    .project-site-avatar {
      width: 100%;
      height: 180px;
      text-align: center;

      img {
        width: 150px;
        height: 150px;
        border-radius: 150px;
      }
    }

    .project-avatar-info {
      margin-bottom: 10px;
      width: 100%;
      text-align: center;
      color: #333;
    }

    .project-site-title {
      display: flex;
      padding: 0 30px;
      width: 100%;
      color: #333;
      font-size: 14px;
      justify-content: space-around;

      span {
        cursor: pointer;
      }

      a {
        color: rgba(0, 0, 0, 0.85);
        transition: all 0.3s;
      }

      a:hover {
        color: #1890ff;
      }
    }
  }
}

.project-logOut {
  margin-right: 28px;
  font-size: 16px;
  color: red;
  cursor: pointer;
}

.project-ewCode {
  font-size: 14px;
  text-align: center;
  margin-top: 80px;

  img {
    width: 150px;
    height: 150px;
  }

  p {
    padding: 5px 0;
  }
}

// .test {
//   margin: 10px;
//   width: 100%;
//   height: 30px;
//   background-color: red;
//   // box-sizing: border-box;
// }